<template>
  <div :class="{wide: useWideView}">
    <NextRaces :horizontal="!useWideView" :number-of-matches="5"></NextRaces>
    <div class="qualifier-content">
      <AutoUpdateTimer update-action-name="updateQualifier"></AutoUpdateTimer>
      <QualifierResults></QualifierResults>
    </div>
  </div>
</template>

<script>
import NextRaces from "@/components/NextRaces";
import AutoUpdateTimer from "@/components/AutoUpdateTimer";
import QualifierResults from "@/components/QualifierResults";
import {mapState} from "vuex";
export default {
  name: "Qualifier",
  components: {QualifierResults, AutoUpdateTimer, NextRaces},
  async mounted() {
    await this.$store.dispatch('initFpvTrackside', {host: this.fpvTracksideHost || 'http://localhost:8080'});
    this.$store.dispatch('updateQualifier', {initial:true});
  },
  computed: {
    ...mapState(['fpvTracksideHost', 'useWideView'])
  }
}
</script>

<style scoped>
.wide {
  display: flex;
  flex-direction: row-reverse;
}
.wide .qualifier-content {
  flex-grow: 1;
  flex-direction: column-reverse;
  display: flex;
}
</style>