import Vue from 'vue'
import Vuex from 'vuex'
import BracketClass, {Match} from "@/shared/bracket";
import Seeding from "@/shared/seeding";
import {
    matchIsComplete,
    getWinnersFromMatch,
    getLosersFromMatch,
    resetMatch,
    getMatchKey,
    renumberAllMatches, mapChannelsToMatch, matchIsEmpty
} from "@/shared/vuex-bracket"
import Fpvtrackside from "@/shared/fpvtrackside";
import {autosave} from "@/shared/save-state";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        matches: {},
        results: {},
        brackets: [],
        bracketOptions: [],
        //playerList: ['MARV_FPV','Dragony','BeefJeff','Fenrir','Godi Fpv','n_N','BIlli','Ouky','Goblin_fpv','Nitr0 FPV','mark8','SWIFT','Lazy','Mewa Kee','Temple Claus','CYJA','E.','LCT','Koli','MikeyDee','Khrom','christie','Robin'].join("\n"),
        playerList: '',
        playerRecords: [{Key:'a',Records:{'1':'1'}}],
        numContestants: 4,
        fpvTracksideHost: null,
        fpvTrackside: null,
        fpvTracksideVerified: null,
        manualMode: false,
        updateInterval: null,
        nextMatchNumber: 1,
        currentMatchNumber: 2,
        openMatchesList: [],
        useWideView: false
    },
    actions: {
        async updateBracket({dispatch, commit, state}) {
            if (state.fpvTrackside === null) {
                console.warn('trackside not connected');
                commit('setManualMode', {manualMode:true})
                return;
            }
            await dispatch('updateFpvTracksideRaces');
            await dispatch('checkForCompletedMatches');
            await dispatch('updateNextMatches');
            await dispatch('autosave');
        },
        async updateQualifier({dispatch, commit, state}, params) {
            if (state.fpvTrackside === null) {
                console.warn('trackside not connected');
                commit('setManualMode', {manualMode:true})
                return;
            }
            await dispatch('updateFpvTracksideQualifier');
            await dispatch('fpvTracksideImportPilots', {initial:params ? params.initial : false});
            await dispatch('updateNextMatchesForTimetrial');
            await dispatch('autosave');
        },
        async autosave({state}) {
            autosave(this);
        },
        async updateFpvTracksideRaces({state}) {
            await state.fpvTrackside.getMatches(true);
        },
        async updateFpvTracksideQualifier({state}) {
            await state.fpvTrackside.getTimetrialMatches(true);
        },
        async checkForCompletedMatches({dispatch, state}) {
            if (!state.fpvTracksideVerified) {
                console.warn('Trackside not inited');
                return;
            }
            let matches = await state.fpvTrackside.getMatches();
            for(let matchKey in state.matches) {
                if (!this.state.matches.hasOwnProperty(matchKey)) {
                    continue;
                }
                let match = state.matches[matchKey];
                if (matchIsComplete(match)) {
                    continue;
                }
                let contestantArray = Object.keys(match.contestants);
                if (contestantArray.length === 0) {
                    continue;
                }
                let race = state.fpvTrackside.getMatchForContestantNames(contestantArray, matches);
                if (race && race.Ended) {
                    race = await state.fpvTrackside.getRaceResults(race);
                    let resultsToBeApplied = race.Results.map(res => res.pilot);
                    resultsToBeApplied.forEach((contestant, idx) => {
                        let resultValue = idx + 1;
                        dispatch('updateMatch', { matchKey, contestant, resultValue })
                    })
                }
            }
        },
        async updateNextMatches({commit,state}) {
            if (!state.fpvTracksideVerified) {
                console.warn('Trackside not inited');
                return;
            }
            let openMatchesList = Object.values(state.matches).filter(match => !matchIsComplete(match));
            openMatchesList.sort((a, b) => {
                return a.globalMatchNumber < b.globalMatchNumber ? -1 : 1;
            });
            openMatchesList = openMatchesList.slice(0, 5);
            let allMatches = await state.fpvTrackside.getMatches();
            openMatchesList.map(match => {
                let race = state.fpvTrackside.getMatchForContestantNames(Object.keys(match.contestants), allMatches);
                mapChannelsToMatch(race, match);
                return match;
            });
            state.matches = Object.assign({}, state.matches);
            commit('openMatchesList', {openMatchesList})
            commit('nextMatchNumber')
        },
        async updateNextMatchesForTimetrial({commit,state}) {
            let allMatches = await state.fpvTrackside.getTimetrialMatches(true);
            state.matches = {};
            let openMatchesList = allMatches.slice(0,5).map(tracksideRace => {
                let [roundNumber, raceNumber] = tracksideRace.RoundRaceNumber.split('-');
                let matchObj = new Match('TimeTrial', roundNumber, raceNumber, 4, 999, false);
                let match = matchObj.toObject();
                match.contestants = {};
                match.globalMatchNumber = parseInt(tracksideRace.RaceOrder)
                let pilotNames = tracksideRace.PilotNames.split(", ");
                pilotNames.forEach(pilotName => match.contestants[pilotName] = {name:pilotName});
                let race = state.fpvTrackside.getMatchForContestantNames(Object.keys(match.contestants), allMatches);
                mapChannelsToMatch(race, match);
                state.matches[matchObj.getKey()] = match;
                return match;
            });
            state.matches = Object.assign({}, state.matches);
            commit('openMatchesList', {openMatchesList})
            commit('nextMatchNumber')
        },
        initBracket({commit, state}, { name, numPlayersInMatch, isDoubleElimination, playerList, seedingStrategy }) {
            let bracketId = state.brackets.length;
            let bracketOptions = [
                name,
                bracketId,
                playerList.length || 4,
                numPlayersInMatch || 4,
                !!isDoubleElimination,
                seedingStrategy
            ];
            let bracket = new BracketClass(...bracketOptions);
            let matches = bracket.getAllMatchesObject();
            let seeding = new Seeding(playerList, numPlayersInMatch);
            let seedingGroups = seeding.getTraditionalSeeding();
            if (seedingStrategy === 'multigp') {
                seedingGroups = seeding.getMultiGPSeeding();
            }
            seedingGroups.forEach((group, number) => {
                let matchKey = bracketId + '1' + (number + 1) + 'WB';
                group.forEach((name) => {
                    if (matches[matchKey] === undefined) {
                        throw new Error('match with key not found ' + matchKey)
                    }
                    matches[matchKey].contestants[name] = {
                        name: name
                    }
                })
            })
            commit('initBracket', { matches, bracket, bracketOptions })
        },
        updateMatch({commit, state, dispatch}, {matchKey,contestant,resultValue}) {
            commit('updateMatchResult', {matchKey,contestant,resultValue})
            if (matchIsComplete(state.matches[matchKey])) {
                commit('moveContestantsToNextMatch', {matchKey})
                dispatch('updateNextMatches');
            }
            if (state.manualMode) {
                dispatch('autosave');
            }
        },
        async initFpvTrackside({commit, state, dispatch}, {host}) {
            state.fpvTracksideVerified = null;
            let fpvtrackside = new Fpvtrackside(host);
            let verified = await fpvtrackside.isConnected();
            commit('updateFpvTrackside', {
                host,
                fpvtrackside: verified ? fpvtrackside : null
            })
            if (!verified) {
                return;
            }
            let event = await fpvtrackside.getEvent()
            if (event.PBLaps !== '3') {
                alert('PBLabs für das Event ist nicht auf 3 gestellt.');
            }
            if (state.playerList.trim() === '') {
                dispatch('fpvTracksideImportPilots', {initial:true})
            }
        },
        async fpvTracksideImportPilots({commit, state}, {initial}) {
            if (!state.fpvTracksideVerified) {
                console.warn('Trackside not inited');
                return;
            }
            let playerList = await state.fpvTrackside.getPilotsForCurrentEvent();
            let playerRecords = playerList.slice();
            let playerPositions = playerRecords.map(pilot => pilot.Key);
            let currentPlayerPositions = state.playerRecords.map(pilot => pilot.Key);
            playerRecords.forEach(pilot => {
                if (initial) {
                    pilot.diff = 0;
                    return;
                }
                pilot.diff = currentPlayerPositions.indexOf(pilot.Key) - playerPositions.indexOf(pilot.Key)
            });

            if (playerPositions.join() !== currentPlayerPositions.join()) {
                commit('updatePlayerRecords', { playerRecords });
            }

            playerList = playerList.map(pilot => pilot.Key).join("\n");
            commit('updatePlayerList', { playerList })
        },
        toggleManual({commit, state}) {
            commit('setManualMode', { manualMode: !state.manualMode})
        },
        toggleWide({commit, state}) {
            commit('setWideMode', { useWideView: !state.useWideView})
        },
        resetMatchResult({commit, dispatch}, options) {
            commit('resetMatchResult', options);
            dispatch('updateNextMatches');
        }
    },
    mutations: {
        initBracket (state, { matches, bracket, bracketOptions }) {
            state.brackets.push(bracket);
            let newMatchList = {...state.matches, ...matches};
            renumberAllMatches(state.brackets, newMatchList)
            state.matches = newMatchList;
            state.bracketOptions.push(bracketOptions);
        },
        resetBracket (state) {
            state.matches = {};
            state.brackets = [];
            state.bracketOptions = [];
            state.nextMatchNumber = 1;
            state.currentMatchNumber = 2;
            state.openMatchesList = [];
        },
        restoreBrackets (state) {
            let newBrackets = [];
            state.bracketOptions.forEach(bracketParams => {
                newBrackets.push(new BracketClass(...bracketParams));
            })
            state.brackets = newBrackets;
        },
        updateMatchResult(state, { matchKey, contestant, resultValue }) {
            let match = state.matches[matchKey];
            match.contestants[contestant].result = resultValue;
            state.matches[matchKey] = Object.assign({}, match);
        },
        resetMatchResult(state, { matchKey }) {
            let matchesToReset = [];
            let match = state.matches[matchKey];
            matchesToReset.push(match);
            matchesToReset.push(...state.brackets[match.bracketId].findForwardConnectedMatches(match, state.matches))
            matchesToReset.forEach((match, key) => {
                state.matches[getMatchKey(match)] = resetMatch(match, key > 0);
            })
            Object.values(state.matches).forEach((match, key) => {
                if (!matchIsComplete(match)) {
                    return;
                }
                let nextMatchLower = state.brackets[match.bracketId].getNextMatchKeyLoser(match);
                let nextMatchUpper = state.brackets[match.bracketId].getNextMatchKeyWinner(match);

                if (nextMatchLower !== null && matchIsEmpty(state.matches[nextMatchLower]) || nextMatchUpper !== null && matchIsEmpty(state.matches[nextMatchUpper])) {
                    this.commit('moveContestantsToNextMatch', {matchKey:getMatchKey(match)});
                }
            });
            state.matches = Object.assign({}, state.matches)
        },
        moveContestantsToNextMatch(state, {matchKey}) {
            let match = state.matches[matchKey];
            let winners = getWinnersFromMatch(match);
            let losers = getLosersFromMatch(match);

            let nextMatchKeyWinner = state.brackets[match.bracketId].getNextMatchKeyWinner(match);
            let nextMatchKeyLoser = state.brackets[match.bracketId].getNextMatchKeyLoser(match);

            // There is no next match, that means
            // the tournament is over.
            if (null === nextMatchKeyWinner) {
                return;
            }

            winners.forEach((contestant) => {
                state.matches[nextMatchKeyWinner].contestants[contestant.name] = {
                    name: contestant.name
                }
            })
            state.matches[nextMatchKeyWinner] = Object.assign({}, state.matches[nextMatchKeyWinner]);

            if (nextMatchKeyLoser) {
                losers.forEach((contestant) => {
                    state.matches[nextMatchKeyLoser].contestants[contestant.name] = {
                        name: contestant.name
                    }
                })
                state.matches[nextMatchKeyLoser] = Object.assign({}, state.matches[nextMatchKeyLoser]);
            }
        },
        updateFpvTrackside(state, {host, fpvtrackside}) {
            state.fpvTracksideHost = host;
            state.fpvTrackside = fpvtrackside;
            state.fpvTracksideVerified = !!fpvtrackside;
            if (!state.fpvTracksideVerified) {
                state.manualMode = true;
            }
        },
        updatePlayerList(state, {playerList}) {
            state.playerList = playerList;
        },
        updatePlayerRecords(state, {playerRecords}) {
            state.playerRecords = playerRecords;
        },
        setManualMode(state, {manualMode}) {
            console.log('setting manual to', manualMode);
            state.manualMode = manualMode;
        },
        setWideMode(state, {useWideView}) {
            console.log('setting wide to', useWideView);
            state.useWideView = useWideView;
        },
        nextMatchNumber(state) {
            if (state.openMatchesList.length > 0) {
                state.currentMatchNumber = state.openMatchesList[0].globalMatchNumber;
            }
            if (state.openMatchesList.length > 1) {
                state.nextMatchNumber = state.openMatchesList[1].globalMatchNumber;
            }
        },
        openMatchesList(state, {openMatchesList}) {
            state.openMatchesList = openMatchesList;
        },
        setUpdateInterval(state, {updateInterval}) {
            state.updateInterval = updateInterval;
        }
    },
    getters: {
        resultForMatch: (state) => (match) => {
            return state.results[match.getKey()] || {};
        }
    }
})

export default store;