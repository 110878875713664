function downloadState(store) {
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(store.state));
    let dlAnchorElem = document.createElement('a');
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", "bracket.json");
    dlAnchorElem.click();
}

async function importState(store, newState) {
    let oldState = Object.assign({}, store.state);
    for (let key in oldState) {
        if (!oldState.hasOwnProperty(key)) {
            continue;
        }
        if (newState[key] === undefined && oldState[key] !== undefined) {
            newState[key] = oldState[key];
        }
    }
    store.replaceState(newState);
    store.commit('restoreBrackets');
    await store.dispatch('initFpvTrackside', { host: store.state.fpvTracksideHost });
}

function autosave(store) {
    let save = {
        date: (new Date()).getTime(),
        data: store.state
    }
    localStorage.setItem('state_autosave', JSON.stringify(save));
}

async function autoload(store) {
    let save = localStorage.getItem('state_autosave');
    if (!save) {
        return;
    }
    let json;
    try {
        json = JSON.parse(save);
    } catch (e) {
        console.warn('Could not load saved JSON');
        return;
    }
    await importState(store, json.data);
}

export {
    downloadState,
    importState,
    autosave,
    autoload,
}