function getSortedContestantsForMatch(match) {
    let contestants = Object.values(match.contestants)
    let contestantDiff = match.numContestants - contestants.length;
    contestants.sort((a, b) => {
        if (a.result === b.result) {
            return 0;
        }
        return a.result < b.result ? -1 : 1;
    })
    for (let i = 0; i < contestantDiff; i++) {
        contestants.push({name:'-', placeholder: true})
    }
    return contestants;
}

function getNextPlacement(match) {
    return Object.values(match.contestants).reduce((carry, contestant) => {
        carry += contestant.result === undefined ? 0 : 1;
        return carry;
    }, 0) + 1
}

function matchIsComplete(match) {
    let contestants = Object.values(match.contestants);
    return contestants.reduce((carry, contestant) => {
        if (contestant.placeholder || contestant.result) {
            carry += 1;
        }
        return carry;
    }, 0) === contestants.length && contestants.length > 0;
}

function matchIsEmpty(match) {
    return Object.values(match.contestants).length === 0
}

function getWinnersFromMatch(match) {
    let contestants = getSortedContestantsForMatch(match);
    let numWinners = match.numContestants / 2;

    let winners = [];
    for (let i = 0; i < numWinners; i++) {
        if (contestants[i] && contestants[i].placeholder !== true) {
            winners.push(contestants[i]);
        }
    }

    return winners;
}

function getLosersFromMatch(match) {
    let contestants = getSortedContestantsForMatch(match);
    let numWinners = match.numContestants / 2;

    let losers = [];
    for (let i = 0; i < numWinners; i++) {
        if (contestants[numWinners + i] && contestants[numWinners + i].placeholder !== true) {
            losers.push(contestants[numWinners + i]);
        }
    }

    return losers;
}

function resetMatch(match, deletePlayers) {
    deletePlayers = deletePlayers || false;
    if (deletePlayers) {
        delete match.contestants;
        match.contestants = {};
    } else {
        for (let contestantName in match.contestants) {
            delete match.contestants[contestantName].result;
        }
    }
    return match;
}

function getMatchKey(match) {
    return '' + match.bracketId + match.round + match.number + (match.isLowerBracket ? 'LB' : 'WB');
}

function renumberAllMatches(brackets, matches) {
	if (Object.values(matches).length === 0) {
        return;
    }
    let maxRounds = Math.max(...brackets.map(bracket => bracket.isDoubleElimination ? bracket.getNumRoundsLowerBracket() : bracket.getNumRounds()));
    let matchNumber = 1;

    for (let i = 0; i < maxRounds; i++) {
        brackets.forEach(bracket => {
            bracket.getMatchesForRound(i + 1).forEach(match => {
                matches[match.getKey()].globalMatchNumber = matchNumber++;
            })
        })
        brackets.forEach(bracket => {
            if (bracket.isDoubleElimination) {
                bracket.getMatchesForRoundLowerBracket(i + 1).forEach(match => {
                    matches[match.getKey()].globalMatchNumber = matchNumber++;
                })
            }
        })
    }
    brackets.forEach(bracket => {
        if (bracket.isDoubleElimination) {
            matches[bracket.getGrandFinalMatch().getKey()].globalMatchNumber = matchNumber++;
        }
    });
}

function mapChannelsToMatch(race, match) {
    if (race) {
        let pilotNames = race.PilotNames.split(", ");
        race.ChannelNames.split(", ").forEach((channelName, idx) => {
            if (match.contestants[pilotNames[idx]]) {
                match.contestants[pilotNames[idx]].channel = channelName;
            }
        });
    } else {
        Object.values(match.contestants).forEach(contestant => {
            delete contestant.channel;
        })
    }
}

function mapChannelsToMatchHowItShouldBe(race, match) {
    if (race) {
        Object.values(race.PilotChannels).forEach(channel => {
            match.contestants[channel.Pilot].channel = channel.Channel.Band.substr(0, 1) + channel.Channel.Number;
        });
    } else {
        Object.values(match.contestants).forEach(contestant => {
            delete contestant.channel;
        })
    }
}

export {
    getSortedContestantsForMatch,
    getNextPlacement,
    matchIsComplete,
    matchIsEmpty,
    getWinnersFromMatch,
    getLosersFromMatch,
    resetMatch,
    getMatchKey,
    renumberAllMatches,
    mapChannelsToMatch,
}