<template>
  <div v-if="bracket" class="bracket">
    <div class="text-container">
      <div class="text">
        <div>
          <h1>{{ bracket.name }}</h1>
        </div>
        <h2>Winner Bracket</h2>
      </div>
      <div class="container">
        <div class="round" v-for="round in bracket.getNumRounds()" :class="{'last': round === bracket.getNumRounds()}" :key="round">
          <Match
              v-for="match in bracket.getMatchesForRound(round)"
              :match-key="match.getKey()"
              :is-double-elimination="bracket.isDoubleElimination"
              :is-last="round === bracket.getNumRounds()"
              :key="match.getKey()"
          ></Match>
        </div>
      </div>
    </div>
    <div class="text-container lower" v-if="bracket.isDoubleElimination">
      <div class="text">
        <h2>Lower Bracket</h2>
      </div>
      <div class="container">
        <div class="round" v-for="round in bracket.getNumRoundsLowerBracket()" :class="{'last': round === bracket.getNumRoundsLowerBracket()}" :key="round">
          <Match
              v-for="match in bracket.getMatchesForRoundLowerBracket(round)"
              :match-key="match.getKey()"
              :is-double-elimination="bracket.isDoubleElimination"
              :is-last="round === bracket.getNumRoundsLowerBracket()"
              :key="match.getKey()"
          ></Match>
        </div>
      </div>
    </div>
    <div class="wb">
      <Match :match-key="bracket.getGrandFinalMatch().getKey()" v-if="bracket.getGrandFinalMatch()"></Match>
    </div>
  </div>
</template>

<script>
import Match from "@/components/Match.vue";

export default {
  name: 'Bracket',
  props: {
    backetId: Number,
  },
  data: () => {
    return {

    }
  },
  components: {
    Match
  },
  computed: {
    bracket() {
      return this.$store.state.brackets[this.backetId];
    }
  }
}
</script>

<style>
.bracket {
  display: grid;
  gap: 0px 0px;
  grid-template-areas:
    ". wb"
    ". wb";
  justify-content: start;
  width: 100%;
  height: 100%;
}
.wb {
  grid-area: wb;
  display: flex;
}
.text {
  padding: 1.5rem 0 .5rem 3.5rem;
}
.lower .text {
  border-right: 1px solid #bbbbbb;
}
.text h2 {
  margin: 0;
  color: white;
  display: inline-block;
  font-size: 1.4em;
}
.text h1 {
  margin: 0;
  margin-bottom: 2rem;
  color: white;
  display: inline-block;
  border-bottom: 3px solid #42464D;
}
.container {
  display: flex;
  width: 100%;
}
.round {
  display: flex;
  flex-direction: column;
}
.round.last {
  flex-grow: 1;
}
.text-container.lower {
  /*border-right: 1px solid white;*/
}
</style>
