class Fpvtrackside
{
    host;
    matches = null;
    timeTrialMatches = null;
    constructor(host) {
        this.host = host;
    }

    async getPilotsForCurrentEvent() {
        let records = await this.request('/LapRecordManager/Records/', [true, ['Value'], ['Records']]);
        records = Object.values(records).map(pilot => {
            let Records = {};
            if (!pilot.Value.Records) {
                console.warn("Can't read pilot records. Your FPVTrackside Version is probably outdated.")
                return { Key: pilot.Key, Records: {} }
            }
            pilot.Value.Records.map(row => {
                let [laps, time] = row.split(", ");
                Records[laps] = parseFloat(time);
            })
            return { Key: pilot.Key, Records }
        });
        records.sort((a, b) => {
            return a.Key.localeCompare(b.Key);
        })
        records.sort((a, b) => {
            return (a.Records['3'] || 99999) < (b.Records['3'] || 99999) ? -1 : 1;
        })
        return records;
    }

    isConnected() {
        return fetch(this.host).then(res => res.status === 200).catch(res => false);
    }

    getEvent() {
        return this.request('/Event/', []);
    }

    async getRaceResults(race) {
        race.Laps = Object.values(await this.request('/RaceManager/Races/' + race.ExternalID + '/Laps/', [true]));
        return this.buildResultsForRace(race);
    }

    getMatchesWithResults() {
        return this.request('/RaceManager/Races/', [true, ['Laps'], true])
            .then(matches => Object.values(matches))
            .then(matches => matches.map(match => { match.Laps = Object.values(match.Laps); return match }))
            .then(matches => matches.map(race => this.buildResultsForRace(race)))
        ;
    }

    buildResultsForRace(race) {
        let lapSums = {};
        if (race.PilotCount === '0') {
            return race;
        }
        race.Laps.forEach((lap) => {
            let pilotName = lap.Detection.substr(10, lap.Detection.indexOf('L' + lap.Number) - 11)
            if (!lapSums[pilotName]) {
                lapSums[pilotName] = {
                    pilot: pilotName,
                    sum: 0,
                    numLaps: 0
                };
            }
            lapSums[pilotName].sum += this.parseTime(lap.Length);
            lapSums[pilotName].numLaps++;
        })
        race.Results = Object.values(lapSums);
        if (race.Results.length != race.PilotCount) {
            race.Results = [];
        } else {
            race.Results = race.Results.sort((a, b) => a.sum < b.sum ? -1 : 1)
            // Lower number of laps supersedes faster times
            race.Results = race.Results.sort((a, b) => a.numLaps > b.numLaps ? -1 : 1)
        }
        return race;
    }

    async getMatches(bypassCache) {
        if (this.matches && !bypassCache) {
            return this.matches;
        }
        return this.matches = await this.request('/RaceManager/Races/', [true])
            .then(matches => Object.values(matches))
            .then(matches => matches.filter(match => match.Type !== 'TimeTrial'))
        ;
    }

    async getTimetrialMatches(bypassCache) {
        if (this.timeTrialMatches && !bypassCache) {
            return this.timeTrialMatches;
        }
        return this.timeTrialMatches = await this.request('/RaceManager/Races/', [true])
            .then(matches => Object.values(matches))
            .then(matches => matches.filter(match => match.Type === 'TimeTrial'))
            .then(matches => matches.filter(match => match.Ended === false))
            .then(matches => {matches.sort((a, b) => parseInt(a.RaceOrder) < parseInt(b.RaceOrder) ? -1 : 1);return matches})
        ;
    }

    getMatchForContestantNames(contestantNames, matches) {
        contestantNames = contestantNames.sort();
        let foundMatches = [];
        for (let i in matches) {
            if (matches.hasOwnProperty(i)) {
                let matchContestantNames = matches[i].PilotNames.split(", ").sort();
                //console.log(matchContestantNames.join(), contestantNames.join(), matchContestantNames.join() === contestantNames.join())
                if (matchContestantNames.join() === contestantNames.join()) {
                    foundMatches.push(matches[i]);
                }
            }
        }

        foundMatches.sort((a, b) => parseInt(a.RaceOrder) > parseInt(b.RaceOrder) ? -1 : 1);

        return foundMatches[0] || null;
    }

    parseTime(time) {
        let value = new Date('1970-01-01 ' + time + ' UTC').getTime();
        if (isNaN(value)) {
            return 0;
        }
        return value;
    }

    async request(url, autoresolve, depth) {
        depth = depth || 0;
        let autoresolveForDepths = autoresolve[depth] || [];
        return fetch(this.host + url)
            .then(res => res.text())
            .then(async text => {
                let json = {};
                let parser = new DOMParser();
                let doc = parser.parseFromString(text, 'text/html');
                let allRows = doc.querySelectorAll('tr');

                if (allRows.length === 0) {
                    return doc.body.innerText;
                }

                let parseRow = async (tr, depth) => {
                    let numTds = tr.querySelectorAll('td').length;
                    let firstTd = tr.querySelector('td:first-child');
                    let lastTd = tr.querySelector('td:last-child');

                    let a = firstTd.querySelector('a');
                    if (autoresolveForDepths === true || (a && autoresolveForDepths.indexOf(a.innerText) > -1)) {
                        let href = new URL(a.href);
                        let key = a.innerText;
                        if (numTds === 1) {
                            // This is a list and we need to avoid duplicate entries
                            key = key + Math.random();
                        }
                        json[key] = await this.request(url + href.pathname, autoresolve, ++depth);
                    } else if(numTds === 1) {
                        if (!Array.isArray(json)) {
                            json = [];
                        }
                        json.push(lastTd.innerText)
                    } else {
                        let value = lastTd.innerText;
                        if (value === 'True') {
                            value = true;
                        } else if (value === 'False') {
                            value = false;
                        }
                        if (a) {
                            json[a.innerText] = value;
                        } else {
                            if (!Array.isArray(json)) {
                                json = [];
                            }
                            json.push(value)
                        }
                    }
                    if (json && json['ExternalID']) {
                        json.Url = url;
                        let match = url.match(/([0-9]+)\/$/);
                        if (match) {
                            json.ExternalID = match[1];
                        }
                    }
                    if (a && json[a.innerText] && a.innerText.indexOf('Invalid') > -1) {
                        delete json[a.innerText];
                    }
                }

                let rowsToParse = [];
                for (let i in allRows) {
                    if (allRows.hasOwnProperty(i)) {
                        rowsToParse.push(parseRow(allRows[i], depth));
                    }
                }

                await Promise.all(rowsToParse);

                return json;
            })
    }
}

export default Fpvtrackside;