<template>
  <div :class="['next-races-container', {'horizontal':horizontal, wide: useWideView}]" v-if="openMatchesList.length > 0">
    <div class="next-races">
      <div v-for="(match, idx) in openMatchesList.slice(0, numberOfMatches)" class="next-race">
        <span class="round-descr">{{roundDescr(idx)}}</span>
        <Match
            :match-key="getMatchKey(match)"
            :is-double-elimination="false"
            :is-last="false"
            :key="getMatchKey(match)"
            :show-channels="true"
            preview
        ></Match>
      </div>
    </div>
  </div>
</template>

<script>
import Match from "@/components/Match";
import {mapState} from "vuex";
import {getMatchKey} from "@/shared/vuex-bracket";
export default {
  name: "NextRaces",
  components: {Match},
  props: {
    numberOfMatches: {
      type: Number,
      default: 3
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['openMatchesList', 'brackets', 'useWideView'])
  },
  methods: {
    getMatchKey,
    roundDescr(idx) {
      return [
          'Jetzt',
          'Nächstes',
          'Übernächstes'
      ][idx] || ''
    }
  }
}
</script>

<style scoped>
.next-races {
  background: #2F3136;
  padding: 1rem;
}
.horizontal .next-races {
  display: flex;
}
.round-descr {
  color: white;
  margin-left: .6rem;
}
.next-race {
  padding-bottom: 1rem;
  flex-grow: 1;
}
.next-race:last-child {
  padding-bottom: 0;
}
</style>