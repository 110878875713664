<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App"
}
</script>

<style>
input, textarea, select, button {
  font-family: 'Quattrocento Sans', sans-serif;
  font-size: 1em;
}
input, select, button {
  padding: .2rem .4rem;
}
button {
  background: #6cfd6c;
  box-sizing: border-box;
  border: 1px #6cfd6c solid;
  cursor: pointer;
}
button:hover {
  border: 1px white solid;
}
</style>