import Vue from 'vue';
import VueRouter from "vue-router";
import Brackets from '@/Brackets.vue'
import Create from '@/Create';
import Qualifier from "@/Qualifier";
import Menu from "@/Menu";

Vue.use(VueRouter);

const routes = [
    { path: '/', component: Menu },
    { path: '/create', component: Create },
    { path: '/bracket', component: Brackets },
    { path: '/qualifier', component: Qualifier },
]

const router = new VueRouter({
    routes,
    mode: 'history',
});

export default router;