class Bracket
{
    size;
    numPlayersInMatch;
    isDoubleElimination;
    matches = {};
    matchesLower = {};
    name;
    id;

    constructor(name, id, totalNumberOfPlayer, numPlayersInMatch, isDoubleElimination) {
        this.name = name;
        this.id = id;
        this.numPlayersInMatch = numPlayersInMatch;
        this.isDoubleElimination = isDoubleElimination;
        this.size = this.nearestPowerOf2(Math.max(1, totalNumberOfPlayer / numPlayersInMatch));
    }

    nearestPowerOf2(n) {
        if (Math.log2(n) % 1 === 0) {
            return n * 2;
        }
        return (1 << 31 - Math.clz32(n)) * 4;
    }

    getAllMatchesObject() {
        let allMatches = {};
        for (let i = 0; i < this.getNumRounds(); i++) {
            this.getMatchesForRound(i + 1).forEach((match) => {
                allMatches[match.getKey()] = match.toObject();
            })
        }

        if (this.isDoubleElimination) {
            for (let j = 0; j < this.getNumRoundsLowerBracket(); j++) {
                this.getMatchesForRoundLowerBracket(j + 1).forEach((match) => {
                    allMatches[match.getKey()] = match.toObject();
                })
            }
            let finalMatch = this.getGrandFinalMatch();
            allMatches[finalMatch.getKey()] = finalMatch.toObject();
        }

        return allMatches;
    }


    getMatchesForRound(round) {
        if (this.matches[round]) {
            return this.matches[round];
        }
        let numMatches = this.getNumMatchesForRound(round);
        if (numMatches < 1) {
            return [];
        }
        let matches = [];
        for (let matchNr = 1; matchNr <= numMatches; matchNr++) {
            matches.push(new Match(this.id, round, matchNr, this.numPlayersInMatch, numMatches));
        }
        return this.matches[round] = matches;
    }

    getMatchesForRoundLowerBracket(lowerBracketRound) {
        if (this.matchesLower[lowerBracketRound]) {
            return this.matchesLower[lowerBracketRound];
        }
        let numMatches = this.getNumMatchesForRoundLowerBracket(lowerBracketRound);
        let matches = [];
        for (let matchNr = 1; matchNr <= numMatches; matchNr++) {
            matches.push(new Match(this.id, lowerBracketRound, matchNr, this.numPlayersInMatch, numMatches, true));
        }
        return this.matchesLower[lowerBracketRound] = matches;
    }

    getGrandFinalMatch() {
        if (!this.isDoubleElimination) {
            return null;
        }
        return new Match(this.id, this.getNumRounds() + 1, 1, this.numPlayersInMatch, 1, false);
    }

    getNumMatchesForRound(round) {
        return this.size / 2 ** round;
    }

    getNumRounds() {
        return Math.log2(this.size);
    }

    getNumRoundsTotal() {
        return this.getNumRounds() + (this.isDoubleElimination ? 1 : 0);
    }

    getNumMatchesForRoundLowerBracket(round) {
        // The uneven rounds always have the same amount of matches,
        // of the previous even round in the lower bracket.
        if (round % 2 === 0) {
            round--;
        }
        let wbRoundReference = Math.ceil(round / 2);
        return this.getNumMatchesForRound(wbRoundReference) / 2;
    }

    getNumRoundsLowerBracket() {
        if (this.size === 4) {
            return 2;
        }
        return 2 * Math.log2(this.size / 2);
    }

    getNextMatchKeyWinner(match) {
        let nextRound = match.round + 1;
        let nextNumber = Math.ceil(match.number / 2);
        let bracket;
        if (match.isLowerBracket) {
            bracket = 'LB';
            if (nextRound%2 === 0) {
                nextNumber = match.number;
            }
            if (nextRound > this.getNumRoundsLowerBracket()) {
                let grandFinal = this.getGrandFinalMatch();
                nextRound = grandFinal.round;
                nextNumber = 1;
                bracket = 'WB';
            }
        } else {
            bracket = 'WB';
            if (nextRound > this.getNumRoundsTotal()) {
                return null;
            }
        }
        return '' + match.bracketId + nextRound + nextNumber + bracket;
    }

    getNextMatchKeyLoser(match) {
        if (match.isLowerBracket) {
            return null;
        }
        let nextRound = (match.round - 1) * 2;
        let nextNumber = match.number;
        let bracket = 'LB';

        if (match.round === 1) {
            nextRound = 1;
            nextNumber = Math.ceil(match.number / 2);
        }

        return '' + match.bracketId + nextRound + nextNumber + bracket;
    }

    findForwardConnectedMatches(match, existingMatches) {
        let matches = [];
        let nextMatch;
        let originalMatch = match;
        while (nextMatch = this.getNextMatchKeyLoser(match)) {
            if (!existingMatches[nextMatch]) {
                break;
            }
            match = existingMatches[nextMatch];
            matches.push(match);
            matches.push(...this.findForwardConnectedMatches(match, existingMatches));
        }
        match = originalMatch;
        while (nextMatch = this.getNextMatchKeyWinner(match)) {
            if (!existingMatches[nextMatch]) {
                break;
            }
            match = existingMatches[nextMatch];
            matches.push(match);
            matches.push(...this.findForwardConnectedMatches(match, existingMatches));
        }

        return matches;
    }
}

class Match
{
    round;
    bracketId;
    numContestants;
    number;
    matchesInRound;
    isLowerBracket;

    constructor(bracketId, round, number, numContestants, matchesInRound, isLowerBracket) {
        this.bracketId = bracketId;
        this.numContestants = numContestants;
        this.round = round;
        this.number = number;
        this.matchesInRound = matchesInRound;
        this.isLowerBracket = isLowerBracket;
    }

    getKey() {
        return '' + this.bracketId + this.round + this.number + (this.isLowerBracket ? 'LB' : 'WB');
    }

    toObject() {
        return {
            bracketId: this.bracketId,
            round: this.round,
            number: this.number,
            matchesInRound: this.matchesInRound,
            isLowerBracket: this.isLowerBracket,
            numContestants: this.numContestants,
            contestants: {}
        }
    }
}

export default Bracket;
export { Match };