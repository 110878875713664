<template>
  <div class="create">
    <h1>Turnierbaum erstellen</h1>
    <form @submit.prevent="createBracket">
      <h2>Gruppen</h2>
      <CreateBracket
          v-for="(def, bracketId) in brackets"
          :defaults="def"
          :bracket-id="bracketId"
          :key="bracketId"
          :is-last="bracketId === brackets.length - 1"
          ref="brackets"
          @delete="deleteBracket"
      ></CreateBracket>
      <button @click.prevent="addBracket">Neue Gruppe</button>
      <h2>Einstellungen</h2>
      <div class="form-row">
        <label>
          Anzahl Teilnehmer pro Match
        </label>
        <select v-model="numContestants">
          <option value="2">2</option>
          <option value="4">4</option>
          <option value="6">6</option>
          <option value="8">8</option>
        </select>
      </div>
      <div class="form-row">
        <label>
          FPV Trackside Adresse
        </label>
        <input type="text" name="fpvtracksidehost" v-model="fpvTracksideHost"><button @click.prevent="verifyFpvTrackside">Überprüfen</button>
        <span v-if="fpvTracksideVerified === true"> Status: Verbunden ☑</span>
        <span v-else-if="fpvTracksideVerified === false"> Status: Fehler ❌</span>
        <span v-else> Status wird überprüft...</span>
      </div>
      <div class="form-row">
        <label>
          Teilnehmer (einer Pro Zeile)
          <button v-if="fpvTracksideVerified" :disabled="pilotsLoading" @click.prevent="importPilots">Von FPVTrackside importieren</button>
        </label>
        <textarea v-model="playerList">

        </textarea>
      </div>
      <div class="form-row">
        <button>Erstellen</button>
      </div>
    </form>
  </div>
</template>

<script>
import CreateBracket from "@/components/CreateBracket";
import {mapState} from "vuex";

export default {
  name: "Create",
  components: {CreateBracket},
  data: () => {
    return {
      brackets: [{
        name: 'Pro Class',
        size: 8,
        double: true,
        seedingStrategy: 'classic'
      },{
        name: 'Sports Class',
        size: 16,
        double: true,
        //seedingStrategy: 'multigp'
        seedingStrategy: 'classic'
      },],
      bracketNum: 2,
      fpvTracksideHost: 'http://localhost:8080',
      pilotsLoading: false
    }
  },
  computed: {
    playerList: {
      get() {
        return this.$store.state.playerList;
      },
      set(val) {
        this.$store.state.playerList = val;
      }
    },
    numContestants: {
      get() {
        return this.$store.state.numContestants;
      },
      set(val) {
        this.$store.state.numContestants = val;
      }
    },
    ...mapState(['fpvTracksideVerified'])
  },
  mounted() {
    this.verifyFpvTrackside();
  },
  methods: {
    createBracket() {
      let bracketData = this.$refs.brackets;
      let bracketsToCreate = [];
      let players = this.playerList.trim().split("\n");
      let offset = 0;
      this.brackets.forEach((bracket, idx) => {
        bracketsToCreate.push({
          name: bracketData[idx].name,
          isDoubleElimination: bracketData[idx].isDoubleElimination,
          numPlayersInMatch: this.numContestants,
          playerList: players.slice(offset, idx === this.brackets.length - 1 ? undefined : bracketData[idx].size),
          seedingStrategy: bracketData[idx].seedingStrategy
        })
        offset += bracketData[idx].size;
      })
      localStorage.setItem('autosave_backup_' + (new Date()).getTime(), localStorage.getItem('state_autosave'));
      localStorage.removeItem('state_autosave');
      this.$store.commit('resetBracket');
      bracketsToCreate.forEach(bracket => {
        this.$store.dispatch('initBracket', bracket)
      })
      this.$router.push({ path: 'bracket' });
    },
    addBracket() {
      this.brackets.push({});
      this.bracketNum++;
    },
    deleteBracket(bracketId) {
      let idx = this.brackets.indexOf(bracketId);
      this.brackets.splice(idx, 1);
    },
    async importPilots() {
      this.pilotsLoading = true;
      await this.$store.dispatch('fpvTracksideImportPilots', {initial:false});
      this.pilotsLoading = false;
    },
    async verifyFpvTrackside() {
      this.$store.dispatch('initFpvTrackside', {
        host: this.fpvTracksideHost
      });
    }
  }
}
</script>

<style>
.create {
  padding-top: 1rem;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  color: white;
}
label {
  min-width: 200px;
  display: block;
}
textarea {
  vertical-align: top;
  width: 100%;
  min-height: 400px;
}
.form-row {
  padding-bottom: 1rem;
}
.continue {
  padding: 1rem 0;
}
a {
  color: white;
}
</style>