<template>
  <div class="qualifier-list">
    <table>
      <thead>
        <tr>
          <th width="15%" colspan="2">Rank</th>
          <th width="45%">Name</th>
          <th width="25%">1 Lap</th>
          <th width="25%">3 Laps</th>
        </tr>
      </thead>
      <TransitionGroup name="list" tag="tbody">
        <template v-for="(pilot, idx) in playerRecords">
          <tr v-if="idx === 99" key="placeholder">
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr :key="pilot.Key">
            <td width="5%">{{idx+1}}</td>
            <td width="10%"><span v-if="pilot.diff !== 0" :class="['position', pilot.diff > 0 ? 'up' : 'down']">{{ pilot.diff > 0 ? '+' : '' }}{{ pilot.diff }}</span></td>
            <td>{{pilot.Key}}</td>
            <td>{{getRecord(pilot, 1)}}</td>
            <td>{{getRecord(pilot, 3)}}</td>
          </tr>
        </template>
      </TransitionGroup>
    </table>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "QualifierResults",
  computed: {
    ...mapState(['playerRecords'])
  },
  methods: {
    getRecord(pilot, key) {
      let record = parseFloat(pilot.Records[key]);
      return record ? record.toFixed(2) : '-';
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
table thead th {
  border-bottom: 1px solid white;
}
th {
  text-align: left;
}
td, th {
  color: white;
  font-size: 2em;
  padding: .5rem;
}
tr:nth-child(even) td {
  background: #42464D;
}
.qualifier-list {
  padding: 0 2rem;
}

.position {
  font-size: .8em;
}

.position.up {
  color: #6cfd6c;
}

.position.up::before {
  content: "▲ ";
  font-size: .8em;
}

.position.down::before {
  content: "▼ ";
  font-size: .8em;
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}
</style>