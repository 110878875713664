<template>
  <div>
    <Bracket v-for="(bracket, bracketId) in brackets" :backet-id="bracketId" :key="bracketId"></Bracket>
    <div class="side-container">
      <h1>Rennen</h1>
      <NextRaces></NextRaces>
      <AutoUpdateTimer></AutoUpdateTimer>
    </div>
  </div>
</template>

<script>
import Bracket from "@/components/Bracket.vue";
import { mapState } from "vuex";
import AutoUpdateTimer from "@/components/AutoUpdateTimer";
import NextRaces from "@/components/NextRaces";

export default {
  name: 'App',
  data: () => {
    return {
    }
  },
  components: {
    NextRaces,
    AutoUpdateTimer,
    Bracket
  },
  watch: {

  },
  computed: {
    ...mapState(['brackets','manualMode'])
  },
  mounted() {
    this.$store.dispatch('updateNextMatches');
  },
  methods: {
    test() {
      this.$store.dispatch('checkForCompletedMatches');
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

html, body{
  padding: 0;
  margin: 0;
  background: #202225;
}
body {
  font-family: 'Quattrocento Sans', sans-serif;
}

.side-container {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}
h1 {
  margin: 0;
  margin-bottom: 2rem;
  color: white;
  display: inline-block;
  border-bottom: 3px solid #666;
}
</style>
