import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import router from "@/routes/routes";
import { downloadState, importState, autoload } from "@/shared/save-state";

Vue.config.productionTip = false

window.save = downloadState.bind(null, store);
window.load = importState.bind(null, store);
window.toggleManual = () => store.dispatch('toggleManual')

autoload(store).then(() => {
  new Vue({
    store,
    router,
    render: h => h(App),
  }).$mount('#app')
});

