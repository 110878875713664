<template>
  <div class="timer">
    <div class="timer-container">
      <div class="controls">
        <div :class="['control-items',{show:manualMode}]">
          <inline-svg :src="require('../assets/wide_toggle.svg')" @click="toggleWide()"></inline-svg>
          <inline-svg :src="require('../assets/play.svg')" v-if="manualMode" @click="toggleManual()"></inline-svg>
          <inline-svg :src="require('../assets/stop.svg')" v-else @click="toggleManual()"></inline-svg>
        </div>
      </div>
      <div class="countdown-text" v-if="!manualMode">
        Update
      </div>
      <div id="countdown" v-if="!manualMode">
        <div id="countdown-number">{{countdownNumber}}</div>
        <svg>
          <circle r="18" cx="20" cy="20"></circle>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex/dist/vuex.esm.browser";
import InlineSvg from 'vue-inline-svg'

export default {
  name: "AutoUpdateTimer",
  components: {
    InlineSvg
  },
  props: {
    updateActionName: {
      type: String,
      default: 'updateBracket'
    }
  },
  data: () => {
    return {
      countdownNumber: 10
    }
  },
  mounted() {
    this.initCountdown();
  },
  destroyed() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  },
  computed: {
    ...mapState(['manualMode','updateInterval'])
  },
  watch: {
    manualMode(newValue) {
      if (newValue === true) {
        clearInterval(this.updateInterval);
      } else {
        this.initCountdown();
      }
    }
  },
  methods: {
    initCountdown() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
      }
      this.countdownNumber = 10;
      let updateInterval = setInterval(() => {
        this.countdownNumber = --this.countdownNumber <= 0 ? 10 : this.countdownNumber;

        if (this.countdownNumber === 1 && !this.manualMode) {
          this.$store.dispatch(this.updateActionName)
        }
        if (this.manualMode) {
          clearInterval(this.updateInterval);
        }
      }, 1000);
      this.$store.commit('setUpdateInterval', {updateInterval})
    },
    toggleManual() {
      this.$store.dispatch('toggleManual');
    },
    toggleWide() {
      this.$store.dispatch('toggleWide');
    }
  }
}
</script>

<style scoped>
.timer {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}
.timer-container {
  display: flex;
  align-items: center;
}
.countdown-text {
  line-height: 40px;
  color: white;
  font-weight: 700;
  padding-right: .5rem;
  text-align: right;
}

#countdown {
  position: relative;
  height: 40px;
  width: 40px;
  text-align: center;
}

#countdown-number {
  color: white;
  display: inline-block;
  line-height: 40px;
}

#countdown svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

#countdown svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: white;
  fill: none;
  animation: countdown 10s steps(10, end) infinite forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

.controls {
  position: relative;
  fill: white;
  padding: 5px;
  cursor: pointer;
}

.control-items {
  display: none;
  text-align: right;
}

.timer:hover .control-items, .control-items.show {
  display: block;
}

.controls svg {
  width: 30px;
  height: 30px;
}

</style>