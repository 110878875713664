<template>
  <div class="parent-container" :class="wrapperClasses">
    <div class="container" :style="containerStyle">
      <div class="Contestant-Area" :style="isLast ? {'grid-template-columns': '1fr auto 1fr'} : {}">
        <div class="Contestant-List" :class="{complete:matchIsComplete(match),next:nextMatchNumber === match.globalMatchNumber,current:currentMatchNumber === match.globalMatchNumber}" @click="copyPilots">
          <span class="round-descr">
            <!-- Round {{match.round}}, Match {{match.number}} -->
            Rennen {{match.globalMatchNumber}}<span v-if="matchIsComplete(match)"> <button @click.prevent="resetMatch" v-if="manualMode">↺</button></span>
          </span>
          <div class="contestants">
            <div v-for="(contestant, idx) in sortedContestants" :class="contestantClasses(contestant, idx)" :key="contestant.name + idx">
              <div class="name">
                {{ contestant.name }}
              </div>
              <div class="meta">
                <div v-if="contestant.result">
                  #{{ contestant.result }}
                </div>
                <button v-if="!contestant.placeholder && contestant.result === undefined && manualMode && !preview" @click="addWin(contestant, nextPlacement)">
                  {{nextPlacement}}
                </button>
                <div v-if="showChannels" class="channel">{{contestant.channel}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="Prev-Down-1"></div>
        <div class="Prev-Up-1"></div>
        <div class="Next-Up-1"></div>
        <div class="Next-Down-1"></div>
      </div>
      <div class="Prev-Down-2"></div>
      <div class="Prev-Up-2"></div>
      <div class="Next-Up-2"></div>
      <div class="Next-Down-2"></div>
    </div>
  </div>
</template>

<script>
import {getSortedContestantsForMatch, getNextPlacement, matchIsComplete} from "@/shared/vuex-bracket";

export default {
  name: "Match",
  props: {
    matchKey: {
      type: String,
      required: true
    },
    isDoubleElimination: {
      type: Boolean
    },
    isLast: {
      type: Boolean
    },
    showChannels: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {

    }
  },
  mounted() {

  },
  computed: {
    match() {
      return this.$store.state.matches[this.matchKey];
    },
    manualMode() {
      return this.$store.state.manualMode;
    },
    nextMatchNumber() {
      return this.$store.state.nextMatchNumber;
    },
    currentMatchNumber() {
      return this.$store.state.currentMatchNumber;
    },
    contestants: function () {
      return this.match.contestants;
    },
    containerStyle() {
      if (this.preview) {
        let opt = {};
        opt['grid-template-columns'] = '10px 1fr 10px'
        opt['grid-template-rows'] = '10px 1fr 10px'
        return opt;
      }
      if (this.match.round === 1 || (this.match.round === 2 && this.match.isLowerBracket)) {
        let opt = {'grid-template-rows': '10px 1fr 10px'};
        return opt;
      }
      let opt = {};
      if (this.isDoubleElimination) {
        opt['grid-template-columns'] = '10px 1fr 10px';
      }
      if (this.isLast) {
        opt['flex-grow'] = '1';
        opt['flex-shrink'] = '0';
        opt['flex-flow'] = 'column';
      }
      return opt;
    },
    wrapperClasses() {
      if (this.preview) {
        return ['preview'];
      }
      return {
        'even': (this.isLast && this.match.isLowerBracket) || this.match.number %2 === 0,
        'odd': !(this.isLast && this.match.isLowerBracket) && this.match.number %2 === 1,
        'prev-line': this.match.round !== 1,
        'not-final': this.isDoubleElimination || this.match.matchesInRound !== 1,
        'next-round-smaller': (this.isLast && this.match.isLowerBracket) || !this.match.isLowerBracket || this.match.round % 2 === 0
      };
    },
    sortedContestants() {
      return getSortedContestantsForMatch(this.match)
    },
    nextPlacement() {
      return getNextPlacement(this.match);
    }
  },
  methods: {
    addWin(contestant) {
      this.$store.dispatch('updateMatch', {
        matchKey: this.matchKey,
        contestant: contestant.name,
        resultValue: this.nextPlacement
      })
    },
    matchIsComplete,
    resetMatch() {
      this.$store.dispatch('resetMatchResult', {matchKey:this.matchKey});
    },
    copyPilots() {
      let pilots = Object.keys(this.match.contestants).join("\n");
      navigator.clipboard.writeText(pilots);
    },
    contestantClasses(contestant, idx) {
      return [
        'contestant',
        {
          'placeholder': contestant.placeholder,
          'dq': (this.isDoubleElimination && this.match.isLowerBracket) && (this.sortedContestants.length / 2 < idx + 1 && matchIsComplete(this.match)),
          'fall': this.isDoubleElimination && this.sortedContestants.length / 2 < idx + 1 && matchIsComplete(this.match)
        }
      ]
    }
  }
}
</script>

<style scoped>
.contestants {
  margin-top: .5rem;
}
.contestant {
  display: flex;
}
.contestant .name {
  flex-grow: 1;
}
.contestant .meta {
  display: flex;
}
.meta .channel {
  font-family: 'Roboto Mono', monospace;
  font-size: .8em;
}
.contestants button {
  font-size: .5rem;
}
.placeholder {
  font-style: italic;
  color: #999;
}
.round-descr {
  display: block;
  font-size: .8rem;
  border-bottom: 1px solid #999;
}
.parent-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
}
.parent-container.preview {
  max-width: 300px;
  min-width: 220px;
}
.container {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr auto 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Prev-Up-2 . Next-Up-2"
    "Contestant-Area Contestant-Area Contestant-Area"
    "Prev-Down-2 . Next-Down-2";
}

.Contestant-Area {
  display: grid;
  min-width: 130px;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Prev-Up-1 Contestant-List Next-Up-1"
    "Prev-Down-1 Contestant-List Next-Down-1";
  grid-area: Contestant-Area;
}

.Contestant-List:hover {
  border: 1px white solid;
  cursor: pointer;
}

.preview .Contestant-Area {
  grid-template-columns: 10px 1fr 10px;
}

.Contestant-List {
  background: #40444B;
  color: white;
  padding: .3rem .3rem;
  grid-area: Contestant-List;
  min-width: 100px;
  /*box-shadow: #939bab 0 0 6px;*/
  border-radius: 3px;
  box-shadow: black 3px 3px 6px;
  border: 1px solid #5f646e;
}

.preview .Contestant-List {
  font-size: 1.8em;
}

.Prev-Down-1 { grid-area: Prev-Down-1; }

.Prev-Up-1 { grid-area: Prev-Up-1; }

.prev-line .Prev-Down-1 {
  border-top: 1px solid #bbbbbb;
  min-width: 10px;
}

.Next-Up-1 {
  grid-area: Next-Up-1;
}

.even .Next-Up-1 {
  border-bottom: 1px solid #bbbbbb;
  min-width: 10px;
}
.next-round-smaller.even .Next-Up-1 {
  border-right: 1px solid #bbbbbb;
  border-bottom-right-radius: 5px;
}

.Next-Down-1 {
  grid-area: Next-Down-1;
}

.odd.not-final .Next-Down-1 {
  border-top: 1px solid #bbbbbb;
}
.next-round-smaller.odd.not-final .Next-Down-1 {
  border-top: 1px solid #bbbbbb;
  border-right: 1px solid #bbbbbb;
  border-top-right-radius: 5px;
}

.Prev-Down-2 { grid-area: Prev-Down-2; }

.Prev-Up-2 { grid-area: Prev-Up-2; }

.Next-Up-2 {
  grid-area: Next-Up-2;
}

.next-round-smaller.even .Next-Up-2 {
  border-right: 1px solid #bbbbbb;
}

.Next-Down-2 {
  grid-area: Next-Down-2;
}

.next-round-smaller.odd.not-final .Next-Down-2 {
  border-right: 1px solid #bbbbbb;
}

.complete {
  background: #2F3136;
}

.current {
  box-shadow: black 3px 3px 6px;
  border: 1px solid #6cfd6c;
}

.next {
  box-shadow: black 3px 3px 6px;
  border: 1px solid #ccffcc;
}

.dq .name, .fall .name, .dq .meta, .fall .meta {
  color: #ababab;
}

.dq .name {
  text-decoration: line-through;
}

</style>