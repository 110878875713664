<template>
  <div>
    <div class="form-row">
      <div class="form-cell">
        <label :for="'name' + this.bracketId">Name</label>
        <input type="text" name="name" v-model="name" :id="'name' + this.bracketId">
      </div>
      <div class="form-cell">
        <label :for="'size' + this.bracketId">
          Max. Gruppen Grösse
        </label>
        <span v-if="isLast"><i>Restliche Spieler</i></span>
        <select :id="'size' + this.bracketId" v-model="size" :style="{display: isLast ? 'none' : 'block'}">
          <option value="4">4</option>
          <option value="8">8</option>
          <option value="16">16</option>
          <option value="32">32</option>
        </select>
      </div>
      <div class="form-cell">
        <label :for="'double' + this.bracketId">
          Double Elimination
        </label>
        <input type="checkbox" v-model="isDoubleElimination" :id="'double' + this.bracketId">
      </div>
      <div class="form-cell">
        <label :for="'seeding' + this.bracketId">
          Seeding
        </label>
        <select :id="'seeding' + this.bracketId" v-model="seedingStrategy">
          <option value="classic">Klassisch</option>
          <option value="multigp">Multi GP</option>
        </select>
      </div>
      <div class="form-cell">
        <button @click.prevent="$emit('delete', bracketId)">␡</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateBracket",
  props: {
    bracketId: Number,
    defaults: Object,
    isLast: Boolean
  },
  data: () => {
    return {
      isDoubleElimination: true,
      name: '',
      size: 8,
      seedingStrategy: 'classic'
    }
  },
  mounted() {
    this.isDoubleElimination = this.defaults.double;
    this.name = this.defaults.name;
    this.size = this.defaults.size;
    this.seedingStrategy = this.defaults.seedingStrategy;
  }
}
</script>

<style scoped>
.form-row {
  display: flex;
}
button {
  vertical-align: bottom;
}
</style>