<template>
  <div class="create">
    <h1>dragony.ch FPV Trackside Companion</h1>
    <div class="options">
      <div class="option">
        <router-link to="qualifier">Qualifier</router-link>
      </div>
      <div class="option" v-if="hasAutosave">
        <router-link to="bracket">Bracket weitermachen</router-link>
      </div>
      <div class="option">
        <router-link to="create">Bracket neu</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  computed: {
    hasAutosave() {
      return !!localStorage.getItem('state_autosave')
    },
  }
}
</script>

<style scoped>
.options {
  display: flex;
}
.options .option {
  display: block;
  padding: 2rem;
  padding-left: 0;
  width: 33.3%;
  box-sizing: border-box;
}
.options .option a {
  display: block;
  background: transparent;
  border: 2px white solid;
  border-radius: 5px;
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
}
.options .option a:hover {
  border: 2px #6cfd6c solid;
}
</style>