class Seeding
{
    names;
    groupSize;

    constructor(names, groupSize) {
        this.names = names;
        this.groupSize = groupSize;
    }

    getTraditionalSeeding() {
        let numMatches = this.getRequiredBracketSize();
        let groups = [];
        let directionUp = true;

        for (let i = 0; i < this.names.length; i++) {
            let offset = i % numMatches;
            let groupNumber = (directionUp ? offset : numMatches - 1 - offset);
            if (offset === numMatches - 1) {
                directionUp = !directionUp;
            }
            if (groups[groupNumber] === undefined) {
                groups[groupNumber] = [];
            }
            groups[groupNumber].push(this.names[i]);
        }

        return this.getBracket(groups);
    }

    getMultiGPSeeding() {
        return [
            [4,7,11,13].map(seed => this.names[seed - 1] || null).filter(n => n),
            [3,6,10,14].map(seed => this.names[seed - 1] || null).filter(n => n),
            [2,5,9,15].map(seed => this.names[seed - 1] || null).filter(n => n),
            [1,8,12,16].map(seed => this.names[seed - 1] || null).filter(n => n),
        ]
    }

    getRequiredBracketSize() {
        return this.nearestPowerOf2(Math.max(1, this.names.length / this.groupSize));
    }

    nearestPowerOf2(n) {
        if (Math.log2(n) % 1 === 0) {
            return n;
        }
        return (1 << 31 - Math.clz32(n)) * 2;
    }

    getBracket(participants) {
        let participantsCount = participants.length;
        let rounds = Math.ceil(Math.log(participantsCount)/Math.log(2));
        /*let bracketSize = Math.pow(2, rounds);
        let requiredByes = bracketSize - participantsCount;*/

        if(participantsCount < 2) {
            return [];
        }

        let matches = [[1,2]];

        for(let round = 1; round < rounds; round++) {
            let roundMatches = [];
            let sum = Math.pow(2, round + 1) + 1;

            for(let i = 0; i < matches.length; i++) {
                let home = this.changeIntoBye(matches[i][0], participantsCount);
                let away = this.changeIntoBye(sum - matches[i][0], participantsCount);
                roundMatches.push([home, away]);
                home = this.changeIntoBye(sum - matches[i][1], participantsCount);
                away = this.changeIntoBye(matches[i][1], participantsCount);
                roundMatches.push([home, away]);
            }
            matches = roundMatches;

        }

        return matches.flat().map(seed => participants[seed - 1] || null);
    }

    changeIntoBye(seed, participantsCount) {
        return seed <= participantsCount ?  seed : null;
    }
}

export default Seeding